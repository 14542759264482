<template>
  <div class="header layout-padding" id="main-header">
    <div
      class="header-inner"
      ref="headerInner"
      :class="{ 'border-0': showSearch }"
    >
      <router-link to="/">
        <div
          style="background: url(/static/images/new-logo.png)"
          class="hidden sm:flex logo"
        />
        <div
          style="background: url(/static/images/logo-icon.png)"
          class="flex sm:hidden logo"
        />
      </router-link>
      <div class="w-full">
        <ul class="flex m-0 p-0 items-center header-items">
          <router-link to="/">
            <li
              class="cursor-pointer menu-item mx-4 md:mx-8 xl:mx-0 text-white"
            >
              <div
                style="background: url(/static/images/home-icon.svg)"
                class="w-7 home-icon hidden xl:flex logo"
              />
            </li>
          </router-link>
          <router-link to="/about">
            <li class="cursor-pointer menu-item text-white hidden xl:flex">
              {{ content.whoWeAre }}
            </li>
          </router-link>
          <router-link to="/services">
            <li class="cursor-pointer menu-item text-white hidden xl:flex">
              {{ content.whatWeDo }}
            </li>
          </router-link>
          <router-link to="/operate">
            <li class="cursor-pointer menu-item text-white hidden xl:flex">
              {{ content.whereWeOperate }}
            </li>
          </router-link>
          <router-link to="/contact">
            <li class="cursor-pointer menu-item text-white hidden xl:flex">
              {{ content.contactUs }}
            </li>
          </router-link>
          <li class="sm:flex">
            <div class="relative">
              <input
                @keyup="gotoSearch"
                type="text"
                class="header-search"
                placeholder="Search"
                v-model="keyword"
              />
              <SearchIcon
                class="search-icon cursor-pointer"
                @click="startSearching"
              />
            </div>
          </li>
          <!--     <li class="flex sm:hidden">
            <div class="relative">
              <SearchIcon
                class="cursor-pointer"
                @click="showSearch = !showSearch"
              />
            </div>
          </li> -->
          <li class="flex xl:hidden" @click="showMobileMenu = !showMobileMenu">
            <div class="hamburger-icon">
              <span class="line" :class="{ cross: showMobileMenu }"></span>
              <span class="line" :class="{ cross: showMobileMenu }"></span>
              <span class="line" :class="{ cross: showMobileMenu }"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-search" :class="{ 'show-mobile-search': showSearch }">
      <div class="relative">
        <input
          type="text"
          class="header-search-mobile flex sm:hidden"
          placeholder="Search"
          v-model="keyword"
        />
        <SearchIcon
          class="search-icon-mobile cursor-pointer"
          @click="startSearching"
        />
      </div>
    </div>
  </div>
  <div class="mobileMenu" :class="{ 'show-mobile-menu': showMobileMenu }">
    <ul class="w-full">
      <li
        style="height: 110px"
        class="
          flex
          justify-between
          items-center
          cursor-pointer
          border-b-1-w
          font-bold
          text-white
          layout-padding
        "
      >
  
      </li>
      <router-link
        class="
          w-full
          layout-padding
          border-b-1-w
          flex
          justify-center
          items-center
        "
        to="/"
      >
        <li
          @click="showMobileMenu = !showMobileMenu"
          class="cursor-pointer font-bold text-white mobile-menu-item"
        >
          {{ content.home }}
        </li>
      </router-link>
      <router-link
        class="
          w-full
          layout-padding
          border-b-1-w
          flex
          justify-center
          items-center
        "
        to="/about"
      >
        <li
          @click="showMobileMenu = !showMobileMenu"
          class="cursor-pointer font-bold text-white mobile-menu-item"
        >
          {{ content.whoWeAre }}
        </li>
      </router-link>
      <router-link
        class="
          w-full
          layout-padding
          border-b-1-w
          flex
          justify-center
          items-center
        "
        to="/services"
      >
        <li
          @click="showMobileMenu = !showMobileMenu"
          class="cursor-pointer font-bold text-white mobile-menu-item"
        >
          {{ content.whatWeDo }}
        </li>
      </router-link>
      <router-link
        class="
          w-full
          layout-padding
          border-b-1-w
          flex
          justify-center
          items-center
        "
        to="/operate"
      >
        <li
          @click="showMobileMenu = !showMobileMenu"
          class="cursor-pointer font-bold text-white mobile-menu-item"
        >
          {{ content.whereWeOperate }}
        </li>
      </router-link>
      <router-link
        class="
          w-full
          layout-padding
          border-b-1-w
          flex
          justify-center
          items-center
        "
        to="/contact"
      >
        <li
          @click="showMobileMenu = !showMobileMenu"
          class="cursor-pointer font-bold text-white mobile-menu-item"
        >
          {{ content.contactUs }}
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import SearchIcon from "/src/assets/Icons/SearchIcon.vue";
export default {
  data() {
    return {
      showSearch: false,
      showMobileMenu: false,
      keyword: "",
    };
  },
  components: {
    SearchIcon,
  },
  computed: {
    allData() {
      let about = this.$store.getters["whoWeAre/whoWeAre"];
      let services = this.$store.getters["services/services"];
      let operations = this.$store.getters["operations/operations"];
      return [about, services, operations];
    },
  },
  watch: {
    showMobileMenu() {
      if (this.showMobileMenu == true) {
        let header = this.$refs.headerInner;
        header.classList.add("border-0");
        document.body.classList.add("overflow-hidden");
      }
      if (this.showMobileMenu == false) {
        let header = this.$refs.headerInner;
        header.classList.remove("border-0");
        document.body.classList.remove("overflow-hidden");
      }
    },
    showSearch() {
      if (this.showSearch) {
        document
          .getElementById("main-header")
          .classList.add("header-height-search");
      } else if (!this.showSearch) {
        document
          .getElementById("main-header")
          .classList.remove("header-height-search");
      }
    },
  },
  methods: {
    gotoSearch(event) {
      if (event.key === "Enter" || event.keyCode === 13) {
        this.startSearching();
      }
    },
    startSearching() {
      this.$router.push({ path: "/search", query: { keyword: this.keyword } });
      this.showSearch = false;
    },
  },

  inject: ["content"],
};
</script>
